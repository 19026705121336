@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Mono);
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'DrukWideHeavy';
  src: local('DrukWideHeavy'), url(/static/media/DrukWideHeavy.7b36517e.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'DrukHeavy';
  src: local('DrukHeavy'), url(/static/media/DrukHeavy.9b29d668.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

html {
  background-color: #141414;
  color: aliceblue;
  scroll-behavior: smooth;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}


body {
  font-family: "IBM Plex Mono";
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
}

.title-image {
  width: 40vw;
  min-width: 275px;
  -webkit-animation: fadeIn forwards 3s;
          animation: fadeIn forwards 3s;
}

.elektra-logo {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 45px;
  height: auto;
  margin-right: 92vw;
  margin-left: 15px;
  z-index: 999;
}

.section {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin: 0 2vw;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: auto;
  object-fit: cover;
  z-index: -1;
}

.video-section {
  height: 100vh;
  width: 100vw;
  position: relative;
  display: inline-block;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50vh;
  margin-top: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: white;
}

.capsule-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
}

.capsule-video {
  width: 100vw;
  -webkit-animation: fadeIn forwards 3s;
          animation: fadeIn forwards 3s;
}

.story-content {
  padding-top: 5vh;
  text-align: left;
  padding-bottom: 5vh;
}

.about {
  min-width: 100vw;
  align-items: center;
  text-align: left;
}

.auction {
  align-items: center;
  min-width: 100vw;
}

.story-text {
  font-size: calc(10px + 0.4vw);
  font-weight: 600;
  padding: 1vh 10vw;
  width: 50vw;
}

.welcome-text {
  font-weight: 900;
  font-size: calc(12px + 0.4vw);
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100vw;
}

.learn-more {
  font-size: calc(11px + 0.4vw);
  color: aliceblue;
  margin-bottom: 50px;
  margin-top: 20px;
}

.learn-more-link:hover {
  color: #4ed0e6;
}

.learn-more-link {
  font-size: calc(11px + 0.4vw);
  font-weight: bold;
  color: #35C1D8;
  margin-bottom: 50px;
}

.about-title {
  margin-top: 75px;
}

.bullet {
  width: 30vw;
  font-size: calc(10px + 0.4vw);
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;

}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.chimpForms {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
}

.chimpForms button {
  font-family: "IBM Plex Mono";
  color: white;
  background-color: transparent;
  border: 1px solid white;
  font-size: 20px;
  padding: 5px 40px 5px 40px;
  margin: 15px 0px 10px 0px;
  -webkit-animation: fadeIn forwards 10s;
          animation: fadeIn forwards 10s;
}

.chimpForms input {
  font-family: "IBM Plex Mono";
  text-align: center;
  color: white;
  font-size: 16px;
  background-color: transparent !important;
  outline: 0;
  border-width: 0 0 1px !important;
  border-color: white;
  border-radius: 0px !important;
  width: 70vw;
  max-width: 420px;
  padding: 5px;
  -webkit-animation: fadeIn forwards 5s;
          animation: fadeIn forwards 5s;
}

::-webkit-input-placeholder {
  color: white;
  opacity: 1;
}

:-ms-input-placeholder {
  color: white;
  opacity: 1;
}

::-ms-input-placeholder {
  color: white;
  opacity: 1;
}

::placeholder {
  color: white;
  opacity: 1;
}

.powerBottom {
  font-size: 12px;
  margin-top: 50px;
  margin-bottom: 25px;
  text-align: center;
  -webkit-animation: fadeIn forwards 15s;
          animation: fadeIn forwards 15s;
  margin-left: auto;
  margin-right: auto;
  color: rgba(255, 255, 255, 0.6);
}

.powerBottom a {
  color: rgba(255, 255, 255, 0.6);
}

.portal-button {
  font-family: "IBM Plex Mono";
  color: white;
  background-color: transparent;
  border: 1px solid white;
  font-size: 18px;
  padding: 5px 40px 5px 40px;
  -webkit-animation: fadeIn forwards 7s;
          animation: fadeIn forwards 7s;
  text-decoration: none;
  cursor: pointer;
}

.party-button {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 25vw;
  padding: 10px 10px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70vw;
  margin-right: auto;
  margin-left: auto;
}

.section-title {
  margin-right: auto;
  margin-left: auto;
  font-size: calc(10px + 1.8vw);
  font-weight: bolder;
  font-family: "DrukWideHeavy";
}


.button:hover {
  border: #333 1.5px solid;
  color: #333;
}

/* mobile */


@media screen and (max-width: 900px) {
  .bullet {
    width: 50vw;
  }
  .story-text {
      width: 75vw;
  }
  .party-button {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 25vw;
    padding: 10px 10px;
  }
}


/*old stuff*/

.content {
  position: relative;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.1);
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.sub-content {
  text-align: center;
  font-size: calc(14px + 0.6vw);
  font-weight: 500;
}

.sub-content h1 {
  color: #ffff;
  font-size: calc(8px + 3vw);
  margin: 0px;
  padding: 0px;
}

.sub-content p {
  color: #eee;
  font-size: 1rem;
  margin: 5px 0;
}

.countdown-text {
  margin-top: 25px;
  opacity: 0;
  font-size: calc(16px + 1.5vw);
  -webkit-animation: fadeIn forwards 5s;
          animation: fadeIn forwards 5s;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.milliseconds {
  max-width: 75px;
  min-width: 75px;
  padding-left: 10px;
  padding-right: 10px;
}
.nft-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100vw;
    padding: 3vh 0px;
}

.nft-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly !important;
    align-items: center;
    text-align: left;
    height: 525px;
    font-size: calc(11px + 0.4vw);
    -webkit-animation: fadeIn forwards 7s;
            animation: fadeIn forwards 7s;
    
}

.nft-visuals {
    width: 275px;
    height: 275px;
    box-shadow: 0 1px 2px rgba(255,255,255,0.15);
    transition: all 0.3s ease-in-out;
}

/* Scale up the box */
.nft-visuals:hover {
    transform: scale(1.02, 1.02);
}
  
.song-party-button {
    margin-top: 25px;
    font-size: calc(10px + 0.3vw);
    min-width: 110px;
    min-height: 40px
}


.zora-textSubdued {
    font-size: 15px !important;
}

.zora-cardAuctionPricing {
    grid-auto-flow: row !important;
    padding: 5px 15px !important;
}

.zora-pricingAmount {
    font-size: 15px !important;
}

.bid-button {
    font-family: "IBM Plex Mono";
    color: white;
    background-color: transparent;
    border: 1.5px solid white;
    font-size: 14px;
    font-weight: bold;
    padding: 0 8px;
    height: 42px;
    width: 85px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.nft-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 50px;
    width: 100%;
    margin-top: auto;
}


.party-button-small {
    width: 40px;
}

.btn-container {
    position: relative;
    height: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.btn {
    position: relative;
    width: 40px;
    height: 40px;
    border: aliceblue 2px solid;
    border-radius: 2px;
    cursor: pointer;
    transition: border 0.1s ease-in-out;
}

.btn:hover {
    border: #333 2px solid;
}

.btn:hover .bar {
    background-color: #333;
}

.btn .bar {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
    width: 2px;
    height: 20px;
    border-radius: 2px;
    background-color: aliceblue;
    transform-origin: center;
    transition: transform 0.4s ease-in-out, background 0.1s ease-in-out;
}

.bar-3 {
    height: 23px;
    border-radius: 0;
}

.btn.pause .bar-1 {
    transform: translateX(13.5px) translateY(0px) rotate(0deg);
}

.btn.pause .bar-2 {
    transform: translateX(24px) translateY(0px) rotate(0deg);
}

.btn.play .bar-1 {
    transform: translateX(20px) translateY(-5px) rotate(-55deg);
}

.btn.play .bar-2 {
    transform: translateX(20px) translateY(5px) rotate(-125deg);
}

.btn.pause .bar-3 {
    transform: translateX(13.5px) translateY(0px) rotate(0deg);
}

.btn.play .bar-3 {
    transform: translateX(12.6px) translateY(0px) rotate(0deg);
}


.price-text {
    font-size: calc(11px + 0.4vw);
    text-align: center;
    width: 100%;
    font-weight: 500;
}

.winner {
    color: #44eb4f;
    font-weight: 700;
}
.nft-title-text {
    font-size: calc(15px + 0.4vw);
    font-weight: 900;
    color: aliceblue;
    text-decoration: none;
}
.nft-artist-text {
    font-size: calc(12px + 0.4vw);
    font-weight: 700;
    color: #a7d1d6;
    text-decoration: none;
}

.winning-address {
    font-size: calc(11px + 0.3vw);
    font-weight: 500;
    text-decoration: none;
    color: aliceblue;
    font-weight: 600;
}

/* small screen nft collapse */

@media screen and (max-width: 900px) {
    .nft-container {
        flex-direction: column;
        align-items: center;
    }

    .nft-card {
        margin-bottom: 75px;
        height: 475px;
    }
}
.keys-button {
    font-family: 'IBM Plex Mono';
    color: white;
    background-color: transparent;
    border: 3px solid white;
    font-size: 32px;
    padding: 40px 20px;
    margin: 15px 3px 10px 3px;
    -webkit-animation: fadeIn forwards 3s;
            animation: fadeIn forwards 3s;
    text-decoration: none;
    cursor: pointer;
  }
  
  .psi {
    color:#d4af37;
    border-color: #d4af37;
  }
  .phi {
    color: #f564fa;
    border-color: #f564fa;
  }

  .keys-vid {
    -webkit-animation: fadeIn forwards 3s;
            animation: fadeIn forwards 3s;
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
