@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono");
@import url("https://fonts.googleapis.com/css?family=Roboto");

@font-face {
  font-family: 'DrukWideHeavy';
  src: local('DrukWideHeavy'), url(./fonts/DrukWideHeavy.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'DrukHeavy';
  src: local('DrukHeavy'), url(./fonts/DrukHeavy.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

html {
  background-color: #141414;
  color: aliceblue;
  scroll-behavior: smooth;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}


body {
  font-family: "IBM Plex Mono";
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
}

.title-image {
  width: 40vw;
  min-width: 275px;
  animation: fadeIn forwards 3s;
}

.elektra-logo {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 45px;
  height: auto;
  margin-right: 92vw;
  margin-left: 15px;
  z-index: 999;
}

.section {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin: 0 2vw;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: auto;
  object-fit: cover;
  z-index: -1;
}

.video-section {
  height: 100vh;
  width: 100vw;
  position: relative;
  display: inline-block;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50vh;
  margin-top: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: white;
}

.capsule-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
}

.capsule-video {
  width: 100vw;
  animation: fadeIn forwards 3s;
}

.story-content {
  padding-top: 5vh;
  text-align: left;
  padding-bottom: 5vh;
}

.about {
  min-width: 100vw;
  align-items: center;
  text-align: left;
}

.auction {
  align-items: center;
  min-width: 100vw;
}

.story-text {
  font-size: calc(10px + 0.4vw);
  font-weight: 600;
  padding: 1vh 10vw;
  width: 50vw;
}

.welcome-text {
  font-weight: 900;
  font-size: calc(12px + 0.4vw);
}

.navbar {
  position: sticky;
  top: 0;
  width: 100vw;
}

.learn-more {
  font-size: calc(11px + 0.4vw);
  color: aliceblue;
  margin-bottom: 50px;
  margin-top: 20px;
}

.learn-more-link:hover {
  color: #4ed0e6;
}

.learn-more-link {
  font-size: calc(11px + 0.4vw);
  font-weight: bold;
  color: #35C1D8;
  margin-bottom: 50px;
}

.about-title {
  margin-top: 75px;
}

.bullet {
  width: 30vw;
  font-size: calc(10px + 0.4vw);
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;

}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.chimpForms {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
}

.chimpForms button {
  font-family: "IBM Plex Mono";
  color: white;
  background-color: transparent;
  border: 1px solid white;
  font-size: 20px;
  padding: 5px 40px 5px 40px;
  margin: 15px 0px 10px 0px;
  animation: fadeIn forwards 10s;
}

.chimpForms input {
  font-family: "IBM Plex Mono";
  text-align: center;
  color: white;
  font-size: 16px;
  background-color: transparent !important;
  outline: 0;
  border-width: 0 0 1px !important;
  border-color: white;
  border-radius: 0px !important;
  width: 70vw;
  max-width: 420px;
  padding: 5px;
  animation: fadeIn forwards 5s;
}

::placeholder {
  color: white;
  opacity: 1;
}

.powerBottom {
  font-size: 12px;
  margin-top: 50px;
  margin-bottom: 25px;
  text-align: center;
  animation: fadeIn forwards 15s;
  margin-left: auto;
  margin-right: auto;
  color: rgba(255, 255, 255, 0.6);
}

.powerBottom a {
  color: rgba(255, 255, 255, 0.6);
}

.portal-button {
  font-family: "IBM Plex Mono";
  color: white;
  background-color: transparent;
  border: 1px solid white;
  font-size: 18px;
  padding: 5px 40px 5px 40px;
  animation: fadeIn forwards 7s;
  text-decoration: none;
  cursor: pointer;
}

.party-button {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 25vw;
  padding: 10px 10px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70vw;
  margin-right: auto;
  margin-left: auto;
}

.section-title {
  margin-right: auto;
  margin-left: auto;
  font-size: calc(10px + 1.8vw);
  font-weight: bolder;
  font-family: "DrukWideHeavy";
}


.button:hover {
  border: #333 1.5px solid;
  color: #333;
}

/* mobile */


@media screen and (max-width: 900px) {
  .bullet {
    width: 50vw;
  }
  .story-text {
      width: 75vw;
  }
  .party-button {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 25vw;
    padding: 10px 10px;
  }
}


/*old stuff*/

.content {
  position: relative;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.1);
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.sub-content {
  text-align: center;
  font-size: calc(14px + 0.6vw);
  font-weight: 500;
}

.sub-content h1 {
  color: #ffff;
  font-size: calc(8px + 3vw);
  margin: 0px;
  padding: 0px;
}

.sub-content p {
  color: #eee;
  font-size: 1rem;
  margin: 5px 0;
}

.countdown-text {
  margin-top: 25px;
  opacity: 0;
  font-size: calc(16px + 1.5vw);
  animation: fadeIn forwards 5s;
  animation-delay: 3s;
}

.milliseconds {
  max-width: 75px;
  min-width: 75px;
  padding-left: 10px;
  padding-right: 10px;
}